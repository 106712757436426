import { Component, OnInit } from "@angular/core";
import { MembershipService } from "../../services/membership/membership.service";
import { AuthService } from "../../services/auth/auth.service";
import { MatDialog } from "@angular/material";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-subscription",
  templateUrl: "./packages.component.html",
  styleUrls: ["./packages.component.scss"],
})
export class PackagesComponent implements OnInit {
  appName = environment.appName;
  constructor(
    public membershipService: MembershipService,
    public dialog: MatDialog,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  purchase() {
    this.membershipService.purchaseSubscription(this.authService.userInfo.uid);
  }
}
