import { Component, OnInit, ElementRef } from "@angular/core";
import { Location } from "@angular/common";
import { AuthService } from "../../services/auth/auth.service";
import { MembershipService } from "../../services/membership/membership.service";
import { MarkerService } from "../../services/marker/marker.service";
import { environment } from "../../../../types/environments/environment";
import { Router } from "@angular/router"; // Import ActivatedRoute

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private navbarVisible: boolean = true;
  private screenWidth: number;
  public appName = environment.appName;

  constructor(
    public location: Location,
    private element: ElementRef,
    public authService: AuthService,
    public membershipService: MembershipService,
    public markerService: MarkerService,
    private router: Router
  ) {
    // Get initial screen width
    this.screenWidth = window.innerWidth;

    // Listen for window resize events
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
      this.checkNavVisibility();
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event.constructor.name === "NavigationEnd") {
        this.checkNavVisibility();
      }
    });
    const navbar: HTMLElement = this.element.nativeElement;
  }

  private checkNavVisibility() {
    this.navbarVisible = this.screenWidth > 1000;
  }
}
