import { Injectable } from "@angular/core";
import { AdminService } from "../admin/admin.service";
import { NotificationsService } from "../notifications/notifications.service";
import { AuthService } from "../auth/auth.service";
import { AnalyticsService } from "../analytics/analytics.service";
import {
  ReviewType,
  Review,
  SubjectMarkerMapping,
  SubjectData,
} from "../../../../types/types";
import { CONTEXT } from "@angular/core/src/render3/interfaces/view";

@Injectable({
  providedIn: "root",
})
export class MarkerService {
  marker = false;
  loaded = false;
  costPerCredit = 0;
  filteredReviews: Review[] = []; // Contains reviews for marker / course leader / peer reviewer
  incompleteReviews: Review[] = []; // All incomplete reviews for admins
  completeReviews: Review[] = []; // All complete reviews for admins
  public markerSubjectCodes: string[] = [];
  public markerSubjects: SubjectData[] = [];
  subjectsList: SubjectData[] = [];
  private reviewsCollection: any;
  public interval;

  constructor(
    private adminService: AdminService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private analyticsService: AnalyticsService
  ) {
    this.startTimer();
    this.authService.user.subscribe(async (user) => {
      if (user) {
        await this.authService.loadUserRights();
        if (this.authService.admin) {
          this.adminService.data.afs
            .collection("markers")
            .snapshotChanges()
            .subscribe(async (markers) => {
              await this.loadSubjects();
              markers.forEach(async (marker) => {
                const markerData =
                  marker.payload.doc.data() as SubjectMarkerMapping;
                markerData["id"] = marker.payload.doc.id;
                markerData["userData"] = await this.getMarkerUserProfile(
                  markerData
                );
                markerData["subjectData"] = await this.getSubjectData(
                  markerData
                );
                markerData["marksCompleted6Months"] =
                  await this.getMarksReviewedLast6Months(markerData["uid"]);
                const index = this.subjectsList.findIndex(
                  (s) => s.key === markerData.subjectId
                );
                this.subjectsList[index].markers.push(markerData);
              });
            });

          // List of completed attempts
          const ts = new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 6;
          this.authService.fs
            .collection("reviews", (ref) =>
              ref
                .where("reviewType", "==", ReviewType.actuary)
                .orderBy("time_submitted", "desc")
                .limit(500)
            )
            .snapshotChanges()
            .subscribe((d) => {
              this.completeReviews = [];
              d.forEach((review) => {
                const reviewData = review.payload.doc.data() as Review;
                reviewData["key"] = review.payload.doc.id;
                if (reviewData["state"] === 2) {
                  this.completeReviews.push(reviewData);
                }
              });
            });
        }

        if (this.authService.courseLeader) {
          this.analyticsService.getAssignmentData();
        }

        if (
          this.authService.marker ||
          this.authService.courseLeader ||
          this.authService.admin
        ) {
          await this.getMarkerSubjects(user);
          const markFees = await this.authService.getCreditInstructionsData(
            null
          );
          this.costPerCredit = markFees["costPerMark"] as number;
          this.loadIncompleteAttempts();
        }
      }
    });
  }

  getTimeLeft() {
    if (this.filteredReviews.length > 0) {
      for (const reviewIndex in this.filteredReviews) {
        if (this.filteredReviews[reviewIndex].state_timestamp) {
          const hoursUntilUnlock = 6;
          const reviewTimestamp =
            this.filteredReviews[reviewIndex].state_timestamp;
          const unlocksAt = reviewTimestamp + hoursUntilUnlock * 60 * 60 * 1000;
          const minsLeft = (unlocksAt - new Date().getTime()) / (1000 * 60);
          let timeLeftMessage = `${Math.floor(
            minsLeft
          )} minutes remaining until the attempt will be opened up to
          other markers`;
          if (Math.floor(minsLeft) <= 1) {
            timeLeftMessage = `The attempt will be opened up to
            other markers within the next 15 minutes`;
          }
          this.filteredReviews[reviewIndex].timeLeft = timeLeftMessage;
        }
      }
    }
  }

  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.getTimeLeft();
    }, 20 * 1000);
  }

  loadSubjects() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("subjects", (ref) => ref.orderBy("rank", "asc"))
        .get()
        .subscribe((subjects) => {
          this.subjectsList = [];
          subjects.forEach((subject) => {
            const s = subject.data() as SubjectData;
            s.markers = [];
            this.subjectsList.push(s);
          });
          resolve(true);
        });
    });
  }

  getMarkerSubjects(user) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", user.uid).where("active", "==", true)
        )
        .get()
        .subscribe((markerSubjects) => {
          this.markerSubjectCodes = [];
          this.markerSubjects = [];
          markerSubjects.forEach(async (subject) => {
            const subjectId = subject.data()["subjectId"];
            const subjectData = await this.authService.getSubject(subjectId);
            const subjectCode = subjectData.code;
            this.markerSubjectCodes.push(subjectCode);
            this.markerSubjects.push(subjectData);
          });
          resolve(true);
        });
    });
  }

  async getMarkerUserProfile(marker) {
    return new Promise(async (resolve, reject) => {
      this.adminService.data.afs
        .collection("users")
        .doc(marker["uid"])
        .get()
        .subscribe((profile) => {
          resolve(profile.data());
        });
    });
  }

  async getMarksReviewedLast6Months(uid: string): Promise<number> {
    return new Promise(async (resolve, reject) => {
      const ts = new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 6;
      return this.adminService.data.afs
        .collection("reviews", (ref) =>
          ref
            .where("reviewType", "==", ReviewType.actuary)
            .where("reviewed_by", "==", uid)
            .where("time_submitted", ">=", ts)
        )
        .get()
        .subscribe((d) => {
          let marks = 0;
          d.forEach((r) => {
            const review = r.data() as Review;
            if (review.state === 2) {
              marks += review.mark;
            }
          });
          resolve(marks);
        });
    });
  }

  async getSubjectData(marker) {
    return new Promise(async (resolve, reject) => {
      this.adminService.data.afs
        .collection("subjects")
        .doc(marker["subjectId"])
        .get()
        .subscribe((subject) => {
          resolve(subject.data());
        });
    });
  }

  toggleMarker(documentId: string, active: boolean) {
    this.adminService.data.afs
      .collection("markers")
      .doc(documentId)
      .update({ active })
      .then((d) => {
        if (!active) {
          this.notificationsService.snack("Marker deactivated");
        } else {
          this.notificationsService.snack("Marker activated");
        }
      });
  }

  getSessionStart() {
    const year = new Date().getFullYear();
    let sessionStart = new Date(year, 0, 1).getTime();
    if (new Date().getMonth() > 5) {
      sessionStart = new Date(year, 6, 1).getTime();
    }
    return new Date(sessionStart).getTime();
  }

  loadIncompleteAttempts() {
    const sessionStart = this.getSessionStart();
    this.authService.fs
      .collection("reviews", (ref) =>
        ref
          .where("reviewed", "==", false)
          .where("time_submitted", ">", sessionStart)
          .orderBy("time_submitted", "asc")
      )
      .snapshotChanges()
      .subscribe(async (d) => {
        this.filteredReviews = [];
        this.incompleteReviews = [];
        d.forEach((review) => {
          const c = review.payload.doc.data() as Review;
          c["key"] = review.payload.doc.id;
          if (!c.costPerCredit) {
            c.costPerCredit = this.costPerCredit;
          }
          c["costForReview"] = c.creditsUsed * c.costPerCredit;

          // For admins
          if (c["reviewType"] && c["reviewType"] !== ReviewType.self) {
            this.incompleteReviews.push(c);
          }

          // Review for marker
          if (
            c["reviewType"] === ReviewType.actuary &&
            this.authService.marker &&
            this.markerSubjectCodes.includes(c["subject"]) &&
            (c["state"] === 0 ||
              (c["state"] === 1 &&
                c["reviewed_by"] === this.authService.userDetails.uid))
          ) {
            this.filteredReviews.push(c);
            this.getTimeLeft();
          }

          // Course leader review allocated to user
          if (
            c["reviewType"] === ReviewType.assignment &&
            c["allocatedMarkerUid"] === this.authService.userDetails.uid
          ) {
            this.filteredReviews.push(c);
          }

          // Peer review allocated to user
          if (
            c["reviewType"] === ReviewType.peer &&
            c["allocatedMarkerUid"] === this.authService.userDetails.uid
          ) {
            this.filteredReviews.push(c);
          }
        });
        this.loaded = true;
      });
  }

  selectReview(attempt) {
    if (attempt.state === 0) {
      let proceed = true;
      if (!this.authService.internetConnected) {
        proceed = false;
        this.notificationsService.snack(
          "Please check your internet connection or refresh the page"
        );
      }
      this.filteredReviews.forEach((review) => {
        if (review.reviewed_by === this.authService.userInfo["uid"]) {
          proceed = false;
          this.notificationsService.snack(
            "You have an attempt locked for marking"
          );
        }
      });
      if (proceed) {
        this.authService.router.navigate(["/mark/" + attempt["key"]]);
      }
    } else {
      if (
        attempt.reviewed ||
        attempt.reviewed_by === this.authService.userInfo["uid"]
      ) {
        this.authService.router.navigate(["/mark/" + attempt["key"]]);
      } else {
        this.notificationsService.snack(
          "Attempt is locked for marking by someone else"
        );
      }
    }
  }

  removeReview(review) {
    this.notificationsService
      .snackConfirm(`Are you sure?`, 10000)
      .onAction()
      .subscribe(() => {
        this.authService.fs
          .collection("reviews")
          .doc(review.key)
          .delete()
          .then((d) => {
            this.notificationsService.snack("Review removed");
          });
      });
  }

  updateCostPerCredit(review) {
    this.authService.fs
      .collection("reviews")
      .doc(review.key)
      .update({ costPerCredit: review.costPerCredit })
      .then((d) => {
        this.notificationsService.snack("Cost updated");
      });
  }
}
