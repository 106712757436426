import { Component, OnInit } from "@angular/core";
import {
  User,
  Tasks,
  SetPackageTask,
  SubjectMarkerMapping,
  SubjectData,
  Subscription,
  SubscriptionName,
  ActivateSubscriptionTask,
  Credit,
} from "../../../../../types/types";
import { AuthService } from "../../../services/auth/auth.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { MembershipService } from "../../../services/membership/membership.service";
import { AdminService } from "../../../services/admin/admin.service";
import { environment } from "../../../../../types/environments/environment";
@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  loaded = false;
  user: User;
  uid: string;
  subjectsList: SubjectData[] = [];
  creditsAdd = 0;
  active: Subscription | null;
  prospective: Subscription | null;
  subjectId: string;
  markerSubjects: string[];
  subscriptions: Subscription[];
  credits: Credit[];

  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public membershipService: MembershipService,
    public admin: AdminService
  ) {
    this.uid = this.getUid(this.authService.router.url);
    this.authService.getUserInfo(this.uid).subscribe(async (userData) => {
      this.user = userData as User;
      this.user.timestamp = await this.getUserLastSeen(this.uid);
      await this.loadSubjects();
      await this.getMarkerSubjects(this.uid);
      this.loadSubscriptions();
      this.loadCredits();
      this.loaded = true;
    });
  }

  ngOnInit() {}

  getUid(url: string): string {
    return url.replace("/admin/users/", "");
  }

  loadSubjects() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .get()
        .subscribe((subjects) => {
          subjects.forEach((subject) => {
            this.subjectsList.push(subject.data() as SubjectData);
          });
          resolve(true);
        });
    });
  }

  async getUserLastSeen(uid): Promise<number> {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("signIns", (ref) =>
          ref.where("uid", "==", uid).orderBy("timestamp", "asc").limitToLast(1)
        )
        .get()
        .subscribe((d) => {
          if (d.empty) {
            resolve(0);
          } else {
            d.forEach((signIn) => {
              resolve(signIn.data()["timestamp"]);
            });
          }
        });
    });
  }

  async createSubscription(subscription: SubscriptionName) {
    if (this.user.professionalBody) {
      const data: SetPackageTask = {
        uid: this.uid,
        type: Tasks.setPackage,
        platform: environment.platform,
        professionalBody: this.user.professionalBody,
        subscription,
        credits: 0,
      };
      this.notificationsService.createTask(Tasks.setPackage, data);
    }
  }

  async activateSubscription() {
    if (this.prospective) {
      const data: ActivateSubscriptionTask = {
        uid: this.uid,
        type: Tasks.activateSubscription,
        subscriptionId: this.prospective.id!,
        platform: environment.platform,
      };
      this.notificationsService.createTask(Tasks.activateSubscription, data);
    } else {
      this.notificationsService.snack("No prospective subscription found");
    }
  }

  async addCredits() {
    await this.membershipService.changeCredits(
      this.creditsAdd,
      "Purchase",
      true,
      this.uid
    );
    this.creditsAdd = 0;
  }

  async makeMarker(uid: string, subjectId: string) {
    if (subjectId) {
      const isMarkerAlready = await this.checkIfMarker(uid, subjectId);
      if (!isMarkerAlready) {
        this.authService.fs
          .collection("markers")
          .add({
            uid: uid,
            active: true,
            subjectId: subjectId,
            lastAllocatedAt: 0,
          })
          .then((d) => {
            this.notificationsService.snack("Marker added");
          });
      } else {
        this.notificationsService.snack(
          "User is a marker for the subject already"
        );
      }
    }
  }

  checkIfMarker(uid: string, subjectId: string) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", uid).where("subjectId", "==", subjectId)
        )
        .get()
        .subscribe((subjects) => {
          resolve(!subjects.empty);
        });
    });
  }

  async getMarkerSubjects(uid: string) {
    return new Promise<void>((resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", uid).where("active", "==", true)
        )
        .get()
        .subscribe((subjectList) => {
          this.markerSubjects = [];
          if (subjectList.docs.length > 0) {
            subjectList.forEach(async (subject) => {
              if (!subjectList.empty) {
                const subjectData = subject.data() as SubjectMarkerMapping;
                const name = await this.authService.getSubjectNameFromKey(
                  subjectData.subjectId
                );
                this.markerSubjects.push(name);
              }
            });
          }
          resolve();
        });
    });
  }

  loadSubscriptions() {
    this.authService.fs
      .collection("memberships", (ref) =>
        ref.where("uid", "==", this.uid).orderBy("timestamp", "desc")
      )
      .snapshotChanges()
      .subscribe((data) => {
        const subscriptions = data.map((doc) => ({
          ...(doc.payload.doc.data() as Subscription),
          id: doc.payload.doc.id,
        }));

        // Calculate days left for active membership
        subscriptions.forEach((subscription) => {
          subscription.expires =
            subscription.timestamp +
            subscription.day_limit * 24 * 60 * 60 * 1000;
        });

        // Sort subscriptions by timestamp
        this.subscriptions = subscriptions;

        // Get active membership
        this.active = subscriptions.find(
          (subscription) => subscription.verified
        ) as Subscription;

        // Calculate days left for active membership
        if (this.active) {
          this.user.daysLeft = this.membershipService.calculateDaysLeft(
            this.active
          );
        }

        // Get prospective membership
        this.prospective = subscriptions.find(
          (subscription) => !subscription.verified
        ) as Subscription;
      });
  }

  deleteAllSubscriptions() {
    if (environment.envName === "dev") {
      if (
        confirm(
          "Are you sure you want to delete all subscriptions for this user?"
        )
      ) {
        this.authService.fs
          .collection("memberships", (ref) => ref.where("uid", "==", this.uid))
          .get()
          .subscribe((querySnapshot) => {
            const batch = this.authService.fs.firestore.batch();
            querySnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });
            batch.commit();
            this.notificationsService.snack("All subscriptions deleted");
          });
      }
    } else {
      this.notificationsService.snack("This action is only available in dev");
    }
  }

  loadCredits() {
    this.authService.fs
      .collection("credits", (ref) => ref.where("uid", "==", this.uid))
      .get()
      .subscribe((data) => {
        this.credits = data.docs.map((doc) => doc.data() as Credit);
        this.credits = this.credits.sort((a, b) => b.time - a.time);
      });
  }
}
