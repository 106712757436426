import { Component, OnInit, Input } from "@angular/core";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { AuthService } from "../../services/auth/auth.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  Desktop,
  PerformanceByReviewType,
  Platforms,
} from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";

@Component({
  selector: "app-performance",
  templateUrl: "./performance.component.html",
  styleUrls: ["./performance.component.scss"],
})
export class PerformanceComponent implements OnInit {
  @Input() desktop: Desktop;
  @Input() desktopType: string;

  public performanceByReviewType: PerformanceByReviewType;
  public chartData = [
    {
      data: [1, 2, 3, 4, 5],
      label: "Series A",
      pointRadius: 10,
    },
  ];
  public chartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          type: "linear",
          position: "bottom",
        },
      ],
    },
  };
  public chartLabels = ["A", "A", "A", "A", "A"];
  public chartColors = [
    {
      pointBackgroundColor: "rgba(255, 99, 132, 0.2)",
      pointBorderColor: "rgba(255,99,132,1)",
      backgroundColor: "rgba(105, 0, 132, .2)",
      borderColor: "rgba(200, 99, 132, .7)",
      borderWidth: 2,
    },
  ];
  public actuarial = environment.platform === Platforms.act;

  constructor(
    public analyticsService: AnalyticsService,
    public authService: AuthService,
    public dataService: DataServiceService
  ) {}

  render() {
    if (this.desktop) {
      if (this.desktop.analytics) {
        if (this.desktop.analytics.performanceByReviewType) {
          this.performanceByReviewType =
            this.desktop.analytics.performanceByReviewType;
        }
      }
    }
  }

  ngOnInit() {
    this.render();
  }

  ngOnChanges() {
    this.render();
  }
}
