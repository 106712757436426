import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { environment } from "../../../../types/environments/environment";
import { MembershipService } from "../../services/membership/membership.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  email = "";
  password = "";
  passwordRepeat = "";
  name = "";
  createAccount = false;
  resetPassword = false;
  appName = environment.appName;

  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public membershipService: MembershipService
  ) {}

  signInEmail() {
    this.authService
      .signInWithEmail(this.email, this.password)
      .then((res) => {});
  }

  signUpEmail() {
    if (this.password !== this.passwordRepeat) {
      return this.notificationsService.snack("Passwords don't match");
    }
    this.authService.signUpWithEmail(this.email, this.password, this.name);
  }

  signOut() {
    this.authService.logout();
  }

  forgotPassword() {
    if (this.email !== "") {
      this.authService.resetPassword(this.email);
    } else {
      this.notificationsService.snack("Please enter a valid email");
    }
  }

  ngOnInit() {}
}
