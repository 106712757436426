import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  ProfessionalBodies,
  ProfessionalBodyDescriptions,
} from "../../../../types/types";
import { environment } from "../../../../types/environments/environment";
import { MembershipService } from "../../services/membership/membership.service";

@Component({
  selector: "app-examiner",
  templateUrl: "./professional-body-selector.component.html",
  styleUrls: ["./professional-body-selector.component.scss"],
})
export class ProfessionalBodySelectorComponent {
  professionalBodies: any[] = [];

  constructor(
    public authService: AuthService,
    private dataService: DataServiceService,
    private membershipService: MembershipService
  ) {
    this.professionalBodies = environment.professionalBodies.map((pb) => ({
      name: pb,
      description: ProfessionalBodyDescriptions[pb],
    }));
  }

  async selectPb(pb: ProfessionalBodies) {
    await this.authService.updateUserProfBody(pb);
    this.dataService.loadSubjects(this.authService.userInfo.uid);
    this.membershipService.load(this.authService.userInfo.uid);
  }
}
