import { Platforms } from "../types";

export const environment = {
  platform: Platforms.act,
  appName: "ThinkActuary",
  description: "Description",
  favicon: "src/assets/img/favicon.ico",
  community: "dummy",
  email: "dummy@dummy.com",
  entity: "ThinkActuary Pty Ltd",
  registrationNumber: "2014/002913/07",
  bankName: "Standard Bank",
  bankAccountNumber: "24 202 989 2",
  vatRate: 0,
  currency: "ZAR",
  professionalBodies: [],
  subjects: [],
  testimonials: [],
  firebase: {},
  production: true,
};
