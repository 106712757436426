import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
@Component({
  selector: "app-email-confirmation",
  templateUrl: "./email-confirmation.component.html",
  styleUrls: ["./email-confirmation.component.scss"],
})
export class EmailConfirmationComponent {
  verificationCode: number;
  constructor(
    public authService: AuthService,
    private notificationsService: NotificationsService
  ) {}

  help() {
    this.notificationsService.snack(
      "Can't spot the email? No worries! Give your spam folder a quick peek (those emails can be sneaky sometimes) or drop us a line at alice@thinkactuary.com. Typed in the wrong email? No stress! Just sign out and create a fresh account with your preferred email.",
      15000
    );
  }
}
