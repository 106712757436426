import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { MembershipService } from "../../services/membership/membership.service";
import { environment } from "../../../../types/environments/environment";

@Component({
  selector: "app-marking-credits",
  templateUrl: "./marking-credits.component.html",
  styleUrls: ["./marking-credits.component.scss"],
})
export class MarkingCreditsComponent {
  private platform = environment.platform;
  constructor(
    public authService: AuthService,
    public membershipService: MembershipService
  ) {}
}
