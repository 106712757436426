import { Component, OnInit } from "@angular/core";
import { AdminService } from "../../../services/admin/admin.service";
import { AuthService } from "../../../services/auth/auth.service";
import { MembershipService } from "../../../services/membership/membership.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  Invoice,
  Tasks,
  POP,
  MarkerReport,
  WeekUsageReport,
  Fees,
  AiConfig,
} from "../../../../../types/types";
import { environment } from "../../../../../types/environments/environment";
import { MatTableDataSource } from "@angular/material/table";

interface SessionTotalMarkerData {
  session: string;
  totalMarks: number;
  avgScore: number;
}

interface SessionWeekData {
  session: string;
  week: number;
  cumulativeMarks: number;
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  sessions: string[] = [];
  selectedSession: string;
  markerData: MarkerReport[] = [];
  usageData: WeekUsageReport[] = [];
  selectedSessionMarkerData: MatTableDataSource<MarkerReport>;
  displayedColumns: string[] = [
    "email",
    "subjects",
    "numberOfQuestions",
    "totalMarks",
    "totalEarnings",
    "score",
    "feedbackScore",
  ];
  displayedColumnsTotals: string[] = ["session", "totalMarks", "avgScore"];
  sessionTotals: MatTableDataSource<SessionTotalMarkerData>;
  usagePlotDataset: any;
  fees: Fees;
  aiConfig: AiConfig;
  public chartLabels: string[] = [];
  public chartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public chartColors: Array<any> = [
    {
      backgroundColor: "rgba(105, 0, 132, .2)",
      borderColor: "rgba(200, 99, 132, .7)",
      borderWidth: 2,
    },
  ];
  public showLegend: boolean = true;

  constructor(
    public admin: AdminService,
    public auth: AuthService,
    public notificationsService: NotificationsService,
    public membershipService: MembershipService
  ) {
    this.loadMarkerData();
    this.loadUsageData();
    this.loadFees();
    this.loadAiConfig();
  }

  goToSubjects() {
    this.admin.router.navigate(["/admin/subjects"]);
  }

  goToUsers() {
    this.admin.router.navigate(["/admin/users"]);
  }

  goToMarkPage() {
    this.admin.router.navigate(["/attempts"]);
  }

  goToPapers() {
    this.admin.router.navigate(["/papers"]);
  }

  goToMarkers() {
    this.admin.router.navigate(["/admin/markers"]);
  }

  becomeMarker(subject) {
    const msg =
      this.auth.userDetails.email +
      " wants to become a marker for " +
      subject["name"];
    this.notificationsService.postToSlack(msg);
    this.notificationsService.snack("Request submitted");
  }

  openInvoice(invoice: Invoice) {
    window.open(invoice.POPURL, "_blank");
  }

  openProfile(invoice: Invoice) {
    this.auth.goToPage(`admin/users/${invoice.uid}`);
  }

  actionPOP(invoice: Invoice, accepted) {
    if (accepted) {
      console.log(invoice);
      this.notificationsService.createTask(Tasks.invoicePaid, {
        invoiceId: invoice.id,
      });
    } else {
      this.notificationsService
        .snackConfirm("Confirm decline?", 1000)
        .onAction()
        .subscribe(() => {
          this.auth.fs.collection("invoices").doc(invoice.id).update({
            POP: POP.declined,
            POPDECLINEREASON: "Invalid document",
          });
        });
    }
  }

  getTotalMarks(weekUsageReport: WeekUsageReport) {
    return (
      weekUsageReport.actuary.marksCompleted +
      weekUsageReport.self.marksCompleted +
      weekUsageReport.peer.marksCompleted
    );
  }

  async loadUsageData() {
    this.usageData = await this.getAllUsageData();
    const sessionWeekCumulativeMarks: SessionWeekData[] = [];
    const sessionWeekMarks: { [key: string]: number[] } = {};
    this.usageData.forEach((data) => {
      const session = `${data.year}_${data.session}`;
      const week = data.week; // Assuming 'week' is the correct property based on the context provided
      if (!sessionWeekMarks[session]) {
        sessionWeekMarks[session] = [];
      }
      const marks = this.getTotalMarks(data);
      if (week !== undefined && marks) {
        // Assuming 'marks' is the correct property based on the context provided
        sessionWeekMarks[session][week] =
          (sessionWeekMarks[session][week] || 0) + marks;
      }
    });

    Object.keys(sessionWeekMarks).forEach((session) => {
      let cumulativeMarks = 0;
      sessionWeekMarks[session].forEach((marks, week) => {
        cumulativeMarks += marks;
        sessionWeekCumulativeMarks.push({ session, week, cumulativeMarks });
      });
    });

    // Sort by session and week
    sessionWeekCumulativeMarks.sort((a, b) => {
      return a.session.localeCompare(b.session) || a.week - b.week;
    });

    this.usagePlotDataset = [];
    this.chartLabels = [];

    Object.keys(sessionWeekMarks).forEach((session) => {
      const sessionData = sessionWeekCumulativeMarks.filter(
        (data) => data.session === session
      );
      if (sessionData.length > 0) {
        this.usagePlotDataset.push({
          data: sessionData.map((data) => data.cumulativeMarks),
          label: session,
        });
        if (this.chartLabels.length === 0) {
          this.chartLabels = sessionData.map((data) => `Week ${data.week}`);
        }
      }
    });
  }

  getAllUsageData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("usage_reporting")
        .snapshotChanges()
        .subscribe(
          (querySnapshot) => {
            if (querySnapshot.length > 0) {
              const usageData = querySnapshot.map(
                (doc) => doc.payload.doc.data() as WeekUsageReport
              );
              resolve(usageData);
            } else {
              resolve([]);
            }
          },
          (error) => reject(error)
        );
    });
  }

  async loadMarkerData() {
    this.markerData = await this.getAllMarkerData();
    const uniqueYearSessionCombinations = new Set();
    this.markerData.forEach((data) => {
      const sessionId = `${data.year}_${data.session}`;
      data["sessionId"] = sessionId;
      uniqueYearSessionCombinations.add(sessionId);
    });
    this.sessions = Array.from(uniqueYearSessionCombinations) as string[];
    this.sessionAnalytics();
    this.selectedSession = this.sessions[this.sessions.length - 1];
    this.selectSession();
  }

  sessionAnalytics() {
    this.sessionTotals = new MatTableDataSource<SessionTotalMarkerData>(
      this.sessions.map((session) => {
        const sessionData = this.markerData.filter(
          (data) => data.sessionId === session && data.totalMarks > 0
        );
        const totalMarks = sessionData.reduce(
          (sum, record) => sum + record.totalMarks,
          0
        );
        const avgScore =
          totalMarks > 0
            ? (100 *
                sessionData.reduce(
                  (acc, record) =>
                    acc +
                    (parseFloat(record.score.replace("%", "")) / 100) *
                      record.totalMarks,
                  0
                )) /
              totalMarks
            : 0;
        return { session, totalMarks, avgScore };
      })
    );
  }

  selectSession() {
    this.selectedSessionMarkerData = new MatTableDataSource<MarkerReport>(
      this.markerData.filter(
        (data) =>
          data.sessionId === this.selectedSession && data.numberOfQuestions > 0
      )
    );
    this.selectedSessionMarkerData.data =
      this.selectedSessionMarkerData.data.sort(
        (a, b) => b.numberOfQuestions - a.numberOfQuestions
      );
    this.selectedSessionMarkerData.data =
      this.selectedSessionMarkerData.data.map((data) => {
        return {
          ...data,
        };
      });
  }

  getAllMarkerData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("marker_reporting")
        .snapshotChanges()
        .subscribe(
          (querySnapshot) => {
            if (querySnapshot.length > 0) {
              const markerData = querySnapshot.map(
                (doc) => doc.payload.doc.data() as MarkerReport
              );
              resolve(markerData);
            } else {
              resolve([]);
            }
          },
          (error) => reject(error)
        );
    });
  }

  loadFees() {
    this.auth.fs
      .collection("config")
      .doc("fees")
      .valueChanges()
      .subscribe((data) => {
        this.fees = data as Fees;
      });
  }

  loadAiConfig() {
    this.auth.fs
      .collection("config")
      .doc("ai")
      .valueChanges()
      .subscribe((data) => {
        this.aiConfig = data as AiConfig;
      });
  }

  validateFees() {
    if (this.fees.costPerCredit <= 0 || this.fees.costPerCredit > 20) {
      this.notificationsService.snack(
        "Cost per credit must be between 0 and 20"
      );
      return false;
    }

    if (this.fees.costPerMark <= 0 || this.fees.costPerMark > 20) {
      this.notificationsService.snack("Cost per mark must be between 0 and 20");
      return false;
    }
    return true;
  }

  validateAiConfig() {
    if (
      this.aiConfig.assessmentFeedbackThreshold <= 0 ||
      this.aiConfig.assessmentFeedbackThreshold >= 5
    ) {
      this.notificationsService.snack(
        "Assessment feedback threshold must be between 0 and 5"
      );
      return false;
    }
    return true;
  }

  save() {
    if (this.validateFees()) {
      this.auth.fs.collection("config").doc("fees").update(this.fees);
      this.notificationsService.snack("Fees updated");
    }

    if (this.validateAiConfig()) {
      this.auth.fs.collection("config").doc("ai").update(this.aiConfig);
      this.notificationsService.snack("AI config updated");
    }
  }
}
