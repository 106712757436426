import { Component } from "@angular/core";
import { AdminService } from "../../../services/admin/admin.service";
import { AuthService } from "../../../services/auth/auth.service";
import {
  SubjectData,
  Tasks,
  User,
  DeleteUserTask,
} from "../../../../../types/types";
import { MatDialog } from "@angular/material/dialog";
import { NotificationsService } from "../../../services/notifications/notifications.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent {
  public searchEmail: string;
  public loaded = false;
  public max_users = 200;
  public users: any[] = [];
  public memberships: string[] = [];
  public subjectsList: SubjectData[] = [];
  constructor(
    private admin: AdminService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notifications: NotificationsService
  ) {
    this.loadSubjects();
    this.load();
  }

  async load() {
    const signInTs = await this.getUserLastSeen();
    this.admin.data.afs
      .collection("users")
      .valueChanges()
      .subscribe(async (users) => {
        this.users = [];
        users.map(async (user) => {
          const userData = user as User;
          const uid = userData.uid;
          userData.timestamp = signInTs[uid] ? signInTs[uid] : 0;
          this.users.push(userData);
        });
        this.users.sort(function (a, b) {
          return b["timestamp"] - a["timestamp"];
        });
        this.loaded = true;
      });
  }

  loadSubjects() {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .get()
        .subscribe((subjects) => {
          subjects.forEach((subject) => {
            this.subjectsList.push(subject.data() as SubjectData);
          });
          resolve(true);
        });
    });
  }

  async getUserLastSeen(): Promise<{ [uid: string]: number }> {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("signIns", (ref) =>
          ref.where("timestamp", ">=", Date.now() - 90 * 24 * 60 * 60 * 1000)
        )
        .get()
        .subscribe((d) => {
          if (d.empty) {
            resolve({});
          } else {
            const signIns = {};
            d.forEach((signIn) => {
              signIns[signIn.data()["uid"]] = signIn.data()["timestamp"];
            });
            resolve(signIns);
          }
        });
    });
  }

  checkIfMarker(uid: string, subjectId: string) {
    return new Promise(async (resolve, reject) => {
      this.authService.fs
        .collection("markers", (ref) =>
          ref.where("uid", "==", uid).where("subjectId", "==", subjectId)
        )
        .get()
        .subscribe((subjects) => {
          resolve(!subjects.empty);
        });
    });
  }

  openUser(uid: string) {
    this.admin.router.navigate(["/admin/users/" + uid]);
  }

  confirmDelete(user: User) {
    this.notifications
      .snackConfirm(`Are you sure you want to delete user ${user.name}?`, 3000)
      .onAction()
      .subscribe(() => {
        this.notifications.createTask(Tasks.deleteUser, {
          userIdToDelete: user.uid,
        });
      });
  }
}
