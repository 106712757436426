import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class AdminGuardService {
  constructor(private router: Router, private authService: AuthService) {}
  async canActivate() {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        await this.authService.loadUserRights();
      }
      if (!this.authService.admin) {
        this.router.navigate(["/"]);
        return false;
      }
    });

    return true;
  }
}
