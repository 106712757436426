import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Tasks } from "../../../../types/types";
import { NotificationsService } from "../../services/notifications/notifications.service";

@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.scss']
})
export class TopicListComponent implements OnInit {

  @Input() topicsLevel1: [];
  @Input() allTopicsLevel2Dict: any;  
  @Input() subjectKey: string;  
  @Input() desktop: any;  

  @Output() topicSelection: EventEmitter<[string, string]> = new EventEmitter(); // Define an output property to emit an array of two strings



  constructor() { }

  ngOnInit() {
  }

  makeTopicSelection(focusTopicId, focusSubTopicId){
    this.topicSelection.emit([focusTopicId, focusSubTopicId]); // Use this method to send data to the parent
  }
  
}
