import { Component, OnInit } from "@angular/core";
import { AdminService } from "../../../services/admin/admin.service";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth/auth.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  Topic,
  Paper,
  QuestionData,
  SubjectData,
  SyllabusPdf,
  Tasks,
  AddPaperEvent,
} from "../../../../../types/types";
import { PapersService } from "../../../services/papers/papers.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { environment } from "../../../../../types/environments/environment";

@Component({
  selector: "app-subject",
  templateUrl: "./subject.component.html",
  styleUrls: ["./subject.component.scss"],
})
export class SubjectComponent implements OnInit {
  public loaded = false;
  private add_topic = false;
  private add_paper = false;
  private subject: any;
  private subjectKey: string;
  private newtopic: Topic = {
    exam: "",
    exam_key: "",
    key: "",
    level: 1,
    question_keys: [],
    questions: [],
    topic: "Topic name",
    description: "Topic description ...",
  };
  private newTopicLevelOne;
  private newTopicLevelString = "2";
  private topics: Topic[];
  private mainTopics: Topic[] = [];
  private subTopics: Topic[] = [];
  private selectedTopic: Topic;
  private papers: Paper[] = [];
  private paperUrl: string | undefined;
  private memoUrl: string | undefined;
  private syllabus: SyllabusPdf = {
    url: undefined,
    path: undefined,
    instructions: "",
  };

  constructor(
    private admin: AdminService,
    private router: Router,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    public paperService: PapersService,
    public storage: AngularFireStorage
  ) {
    this.subjectKey = router.url.replace("/admin/subjects/", "");
    this.papers = this.paperService.papers.filter(
      (p) => p.subjectId === this.subjectKey
    );
    this.admin.data.afs
      .collection("subjects")
      .doc(this.subjectKey)
      .snapshotChanges()
      .subscribe((subjectSnapshot) => {
        if (subjectSnapshot.payload.exists) {
          this.admin.subject = subjectSnapshot.payload.data() as SubjectData;
          if (this.admin.subject.syllabus) {
            if (this.admin.subject.syllabus.instructions) {
              this.syllabus.instructions =
                this.admin.subject.syllabus.instructions;
            }
          }
        } else {
          console.error("Subject data not found");
        }
      });
    this.admin.data.afs
      .collection("subjects")
      .doc(this.subjectKey)
      .get()
      .toPromise()
      .then(async (subjectData) => {
        this.loadPapers(this.subjectKey);
        this.loadTopics(this.subjectKey);
      })
      .catch((error) => {
        console.error("Error fetching subject data: ", error);
      });
  }

  loadPapers(subjectKey) {
    this.authService.fs
      .collection("papers", (ref) => ref.where("subjectId", "==", subjectKey))
      .snapshotChanges()
      .subscribe(async (snapshot) => {
        this.papers = snapshot.map((doc) => doc.payload.doc.data() as Paper);
        for (let paper of this.papers) {
          paper.questionData = await this.loadQuestions(paper.id);
        }
        for (let index = 0; index < this.papers.length; index++) {
          let paper = this.papers[index];
          paper.refresh = false;
          if (paper.questionData) {
            for (let j = 0; j < paper.questionData.length; j++) {
              let q = paper.questionData[j];
              if (q.editInstructions !== undefined) {
                paper.refresh = true;
              }
            }
          }
        }
        this.loaded = true;
        this.papers.sort((a, b) => {
          if (a.year !== b.year) {
            return b.year - a.year;
          } else if (a.month !== b.month) {
            return b.month - a.month;
          } else {
            return b.paperNumber - a.paperNumber;
          }
        });
      });
  }

  loadQuestions(paperId: string): Promise<QuestionData[]> {
    return new Promise<QuestionData[]>((resolve, reject) => {
      this.authService.fs
        .collection("questions", (ref) => ref.where("paperId", "==", paperId))
        .get()
        .subscribe((querySnapshot) => {
          const questions = querySnapshot.docs.map(
            (doc) => doc.data() as QuestionData
          );
          resolve(questions);
        });
    });
  }

  ngOnInit() {}

  openPaper(paper: Paper) {
    this.authService.router.navigate([`/papers/${paper.id}`]);
  }

  loadTopics(subjectKey) {
    this.admin.getSubjectTopics(subjectKey).subscribe((topic_data) => {
      this.topics = topic_data.map(
        (topic) => topic.payload.doc.data() as Topic
      );
      this.mainTopics = this.topics.filter((t) => t.level === 1);
      this.mainTopics;
      //@ts-ignore
      this.mainTopics.sort((a, b) => a.index - b.index);
    });
  }

  selectTopic(topic: Topic) {
    this.selectedTopic = topic;
    if (topic.level === 1) {
      this.subTopics = this.topics.filter(
        (t) => t.key.includes(topic.key) && t.level === 2
      );
      //@ts-ignore
      this.subTopics.sort((a, b) => a.index - b.index);
    }
  }

  updateSelectedTopic() {
    if (!this.selectedTopic) {
      return this.notificationsService.snack("No topic selected.");
    }
    this.admin.data.afs
      .collection("topics")
      .doc(this.selectedTopic.key)
      .update({
        topic: this.selectedTopic.topic,
        description: this.selectedTopic.description,
        objectives: this.selectedTopic.objectives,
        skills: this.selectedTopic.skills,
      })
      .then(() => {
        this.notificationsService.snack("Topic updated successfully.");
      })
      .catch((error) => {
        this.notificationsService.snack(
          "Error updating topic: " + error.message
        );
      });
  }

  removeSelectedTopic() {
    if (!this.selectedTopic) {
      return this.notificationsService.snack("No topic selected.");
    }
    this.notificationsService
      .snackConfirm(`Confirm delete "${this.selectedTopic.topic}"?`)
      .onAction()
      .subscribe(() => {
        this.admin.data.afs
          .collection("topics")
          .doc(this.selectedTopic.key)
          .delete()
          .then((d) => {
            this.notificationsService.snack("Topic deleted!");
          });
      });
  }

  goToQuestion(question) {
    this.admin.router.navigate(["/admin/questions/" + question["id"]]);
  }

  addTopic() {
    let key = "";
    if (this.newTopicLevelString === "1") {
      key = this.subjectKey + "_" + this.newtopic.topic;
    } else {
      key = this.newTopicLevelOne + "_" + this.newtopic.topic;
    }
    key = key.toLowerCase().replace(" ", "").replace(/\s+/g, "");
    const newTopic: Topic = {
      level: parseFloat(this.newTopicLevelString),
      exam: this.subjectKey,
      exam_key: this.subjectKey,
      key,
      question_keys: [],
      questions: [],
      topic: this.newtopic.topic,
      index: this.newtopic.index,
      description: this.newtopic.description,
    };
    console.log(key);
    this.admin.data.afs
      .collection("topics")
      .doc(newTopic.key)
      .set(newTopic)
      .then(() => {
        this.notificationsService.snack("Topic added!");
        this.add_topic = false;
      })
      .catch((error) => {
        this.notificationsService.snack("Error adding topic: " + error.message);
      });
  }

  searchBracket(string) {
    console.log(string);
    return string.includes("[") || string.includes("]");
  }

  getTimestamp(paper, number) {
    const i = paper.replace("_", "").toString() + (1 / number).toString();
    const n = parseFloat(i);
    return Math.round(n * 1000) / 1000;
  }

  getSubQuestionIdentifier(i): string {
    if (i === 1) {
      return "i";
    }
    if (i === 2) {
      return "ii";
    }
    if (i === 3) {
      return "ii";
    }
    if (i === 4) {
      return "iv";
    }
    if (i === 5) {
      return "v";
    }
    if (i === 6) {
      return "vi";
    }
    if (i === 7) {
      return "vii";
    }
    if (i === 8) {
      return "viii";
    }
    return "x";
  }

  addPaper() {
    if (!this.paperUrl) {
      this.notificationsService.snack("Paper PDF required.");
      return;
    }
    const task: AddPaperEvent = {
      subjectId: this.subjectKey,
      paperUrl: this.paperUrl,
      uid: this.authService.userInfo.uid,
      type: Tasks.paperCreationWorkflow,
      platform: environment.platform,
      update: false,
    };
    if (this.memoUrl) {
      task.memoUrl = this.memoUrl;
    }
    this.notificationsService.createTask(Tasks.paperCreationWorkflow, task);
    this.notificationsService.snack(
      "Paper workflow started. See workflow log (bottom of page)."
    );
    this.add_paper = false;
    this.paperUrl = undefined;
    this.memoUrl = undefined;
  }

  updatePaper(paper: Paper) {
    const task: AddPaperEvent = {
      paperId: paper.id,
      subjectId: this.subjectKey,
      uid: this.authService.userInfo.uid,
      type: Tasks.paperUpdateWorkflow,
      platform: environment.platform,
      update: true,
    };
    this.notificationsService.createTask(Tasks.paperCreationWorkflow, task);
    this.notificationsService.snack("Paper update workflow started");
  }

  rerunPaper(paper: Paper) {
    const task: AddPaperEvent = {
      paperId: paper.id,
      subjectId: this.subjectKey,
      uid: this.authService.userInfo.uid,
      type: Tasks.paperUpdateWorkflow,
      platform: environment.platform,
      update: false,
    };
    if (confirm("Are you sure you want to rerun this paper?")) {
      this.notificationsService.createTask(Tasks.paperCreationWorkflow, task);
      this.notificationsService.snack("Paper rerun workflow started");
    }
  }

  deletePaper(paper: Paper) {
    if (
      !confirm(`Confirm delete paper ${paper.year} session ${paper.session}?`)
    ) {
      return;
    }
    this.authService.fs
      .collection("papers")
      .doc(paper.id)
      .delete()
      .then(() => {
        this.notificationsService.snack("Paper deleted successfully.");
        this.papers = this.papers.filter((p) => p.id !== paper.id);
        this.authService.fs
          .collection("questions", (ref) =>
            ref.where("paperId", "==", paper.id)
          )
          .get()
          .subscribe((querySnapshot) => {
            const batch = this.authService.fs.firestore.batch();
            querySnapshot.docs.forEach((doc) => {
              batch.delete(doc.ref);
            });
            batch
              .commit()
              .then(() => {
                this.notificationsService.snack(
                  "Questions deleted successfully."
                );
              })
              .catch((error) => {
                this.notificationsService.snack(
                  "Error deleting associated questions: " + error.message
                );
              });
          });
      })
      .catch((error) => {
        this.notificationsService.snack(
          "Error deleting paper: " + error.message
        );
      });
  }

  toggleSubjectActive() {
    this.authService.fs
      .collection("subjects")
      .doc(this.subjectKey)
      .update({ active: this.admin.subject.active })
      .then((d) => {
        if (this.admin.subject.active) {
          this.notificationsService.snack("Subject activated");
        } else {
          this.notificationsService.snack("Subject deactivated");
        }
      });
  }

  onSyllabusSelected($event) {
    const file: File = $event.target.files[0];
    const extension = file.type;
    if (extension == "application/pdf") {
      const path = `syllabus/${new Date().getTime()}.pdf`;
      this.storage.upload(path, file).then(async (d) => {
        const url = await d.ref.getDownloadURL();
        this.syllabus.path = path;
        this.syllabus.url = url;
      });
    } else {
      this.notificationsService.snack("PDF format required");
    }
  }

  createSyllabus() {
    const syllabus = this.syllabus;
    this.admin.data.afs
      .collection("subjects")
      .doc(this.subjectKey)
      .update({ syllabus })
      .then(() => {
        this.notificationsService.createTask(Tasks.topicCreationWorkflow, {
          subjectId: this.subjectKey,
        });
        this.notificationsService.snack(
          "Syllabus workflow started. See workflow log (bottom of page)."
        );
        this.syllabus.url = undefined;
      })
      .catch((error) => {
        this.notificationsService.snack("Error updating syllabus: ", error);
      });
  }

  uploadToStorage($event, documentType: string) {
    const file: File = $event.target.files[0];
    const extension = file.type;
    if (extension == "application/pdf") {
      const path = `documents/${new Date().getTime()}.pdf`;
      this.storage.upload(path, file).then(async (d) => {
        const url = await d.ref.getDownloadURL();
        if (documentType === "paper") {
          this.paperUrl = url;
          this.notificationsService.snack("Paper uploaded");
        }
        if (documentType === "memo") {
          this.memoUrl = url;
          this.notificationsService.snack("Memo uploaded");
        }
      });
    } else {
      this.notificationsService.snack("PDF format required");
    }
  }

  openMcq() {
    this.router.navigate([`/admin/subjects/${this.subjectKey}/mcq`]);
  }

  generateMcqs() {
    this.notificationsService.createTask(Tasks.mcqGenerationWorkflow, {
      subjectId: this.subjectKey,
    });
  }

  toggleReleaseStatus(paper: Paper) {
    this.paperService.setPaperReleaseStatus(paper);
  }
}
