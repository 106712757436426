import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth/auth.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DataServiceService } from "../../../services/data/data-service.service";
import {
  MultipleChoiceQuestion,
  SubjectData,
} from "../../../../../types/types";

@Component({
  selector: "app-admin-mcq",
  templateUrl: "./mcq.component.html",
  styleUrls: ["./mcq.component.scss"],
})
export class McqAdminComponent implements OnInit {
  subjectId: string;
  questions: MultipleChoiceQuestion[] = [];
  filteredQuestions: MultipleChoiceQuestion[] = [];
  subject: SubjectData;
  subjectList: SubjectData[] = [];
  showUnreviewedOnly = false;
  stats = {};

  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public dataService: DataServiceService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.subjectId = this.getSubjectId(this.authService.router.url);
        this.load();
      } else {
        this.authService.router.navigate(["/"]);
      }
    });
  }

  getSubjectId(url: string): string {
    const parts = url.split("/");
    return parts[parts.length - 2];
  }

  async load() {
    this.authService.fs
      .collection("multiple_choice", (ref) =>
        ref.where("subjectId", "==", this.subjectId)
      )
      .get()
      .toPromise()
      .then((snapshot) => {
        this.questions = snapshot.docs.map(
          (doc) => doc.data() as MultipleChoiceQuestion
        );
      });
  }

  filterQuestions(filter: "all" | "unreleased") {
    if (filter === "all") {
      this.filteredQuestions = this.questions;
    } else if (filter === "unreleased") {
      this.filteredQuestions = this.questions.filter(
        (question) => question.reviewed === false
      );
    }
  }
  ngOnInit() {}

  saveImprovementNotes(question: MultipleChoiceQuestion) {
    this.authService.fs
      .collection("multiple_choice")
      .doc(question.id)
      .update({ improvementNotes: question.improvementNotes })
      .then(() => {
        this.notificationsService.snack("Improvement notes saved");
      })
      .catch((error) => {
        this.notificationsService.snack(
          "Error saving improvement notes: ",
          error
        );
      });
  }

  toggleReleaseStatus(question: MultipleChoiceQuestion) {
    if (question.improvementNotes.trim() === "") {
      if (confirm("Are you sure you want to update the release status?")) {
        this.authService.fs
          .collection("multiple_choice")
          .doc(question.id)
          .update({ released: question.released })
          .then(() => {
            this.notificationsService.snack("Release status updated");
          })
          .catch((error) => {
            this.notificationsService.snack(
              "Error updating release status: ",
              error
            );
          });
      }
    } else {
      this.notificationsService.snack(
        "Cannot release question with improvement notes. Please clear the notes before releasing."
      );
    }
  }
}
