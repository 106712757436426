import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { CreditInstructionsComponent } from "../../dialogs/credit-instructions/credit-instructions.component";
import { MarkConfirmComponent } from "../../dialogs/mark-confirm/mark-confirm.component";
import { ReviewTypeSelectedTopicComponent } from "../../dialogs/review-type-selected-topic/review-type-selected-topic.component";
import { ExamPressureEndComponent } from "../../dialogs/exam-pressure-end/exam-pressure-end.component";
import { ExamPressureSubmitComponent } from "../../dialogs/exam-pressure-submit/exam-pressure-submit.component";
import { ExamSelectionComponent } from "../../dialogs/exam-selection/exam-selection.component";
import { AI } from "../../dialogs/ai/ai.component";
import { QuestionsComponent } from "../../dialogs/questions/questions.component";
import { PodcastComponent } from "../../dialogs/podcast/podcast.component";

import * as ClassicEditor from "../../ckeditor5/build/ckeditor";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import { DataServiceService } from "../../services/data/data-service.service";
import {
  ReviewType,
  QuestionData,
  SubjectData,
  SlackChannels,
  Review,
  SelectExamOutput,
  ExamReview,
  User,
  Tasks,
  CreateSheetTask,
  Sheet,
  DesktopStates,
  Topic,
  Desktop,
  Paper,
  MultipleChoiceQuestion,
  MultipleChoiceQuestionAnswer,
  MultipleChoiceQuestionAttempt,
} from "../../../../types/types";
import {
  MarkDiaglogInput,
  MarkDiaglogOutput,
} from "../../dialogs/mark-confirm/mark-confirm.component";
import { SubQuestionSelection } from "../../components/sub-question-selection/sub-question-selection.component";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { text } from "@angular/core/src/render3";
import { AdvertiserPopupComponent } from "../../dialogs/advertiser/advertiser.component";
import { environment } from "../../../../types/environments/environment";

enum State {
  loading = "loading",
  selectTopic = "selectTopic",
  recommendationLoading = "recommendationLoading",
  doQuestions = "doQuestions",
}

enum Loading {
  subject = "Loading subject, hang tight ...",
  question = "Loading question ...",
}

interface Continue {
  text: string;
  visible: boolean;
  topicId: string;
  subTopicId: string;
}

@Component({
  selector: "app-desktop-mcq",
  templateUrl: "./desktop-mcq.component.html",
  styleUrls: ["./desktop-mcq.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DesktopMCQComponent {
  @ViewChild("top") subjectsHTML: ElementRef;

  public state: State = State.loading;
  public loadingDescription: Loading = Loading.subject;
  public subjectCode = "";
  public subjectKey: string;
  public subjectData: SubjectData;
  public desktop: Desktop;
  public topics: Topic[] = [];
  public topicsLevel1: Topic[] = [];
  public allTopicsLevel2: Topic[] = [];
  public allTopicsLevel2Dict: { [key: string]: Topic[] } = {};
  public topicsLevel2: Topic[] = [];
  public questionsToDo: QuestionData[];
  public questionData: QuestionData;
  public selectedTopicL1: Topic | undefined;
  public selectedTopicL2: Topic | undefined;
  public continue: Continue = {
    visible: false,
    text: "",
    topicId: "",
    subTopicId: "",
  };

  // Question and Memo section
  public Editor = ClassicEditor;
  public source = null;
  public questionCount = 0;
  public question_html = null;
  public memo_html = null;
  public questionAttempt = 0;
  public secsBeforeFeedback = 60;
  public topicSelectedQuestion = false;
  public busySaving = false;
  public subjectCodeDisplay = "";
  public subjectName = "";
  public examPressureOn = false;
  public examPressureReviewType: ReviewType;
  public selectedQuestionIndex = 0;
  public submittedForMarking = false;
  public subQuestionSelection: SubQuestionSelection;
  public toBePeerReviewed = false;
  public canRequestPeerReview = false;
  public isAssignment: boolean = false;
  public canAddSheet = false;
  public currentQuestionLoadedTimestamp = new Date().getTime();
  public sheetLink;
  public attemptText = "";
  public attempted = false;
  public credits = 0;
  public reviews = [];
  public unfinishedExamAttempts: ExamReview[] = [];
  public selectedQuestionKey: string | undefined;
  public desktopType: string;

  // Timer section
  public mark = 0;
  public timeLeft = 0;
  public timeLeft_thres = 0;
  public questionAttempted = false;
  public interval;
  public min_remaining = 0;
  public sec_remaining = 0;
  public time = 0;
  public secondsPerMark = 1.8 * 60;
  public acceptingActuaryReviews = false;
  public acceptingPeerReviews = false;
  public acceptingAiReviews = false;

  // Advertiser
  public showAd = false;

  // MCQ variable (move to component) //////////////////////////////////////////////
  currentQuestion: MultipleChoiceQuestion | undefined;
  selectedAnswer: MultipleChoiceQuestionAnswer | undefined;
  showAnswer = false;
  topicName = "";
  subTopicName = "";
  selectedOption: number | undefined;
  subject: SubjectData;

  public mcqsToDo: MultipleChoiceQuestion[];
  public mcqData: MultipleChoiceQuestion;
  ////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private membershipService: MembershipService,
    private dataService: DataServiceService,
    public analyticsService: AnalyticsService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.subjectCode = this.getSubjectCode(this.authService.router.url);
        this.desktopType = this.getDesktopType(this.authService.router.url);
        this.setCurrentSubjectCode(this.subjectCode, user.uid);
        const validSubject = await this.authService.validateSubjectKey(
          this.subjectCode
        );
        if (validSubject) {
          this.authService.userInfo = await this.authService.getUserInfoOnce(
            user.uid
          );
          if (!this.authService.userInfo["tcsAccepted"]) {
            this.authService.termsAcceptedCheck();
          }
          this.notificationsService.postToSlack(
            "desktop-mcq",
            SlackChannels.pages
          );
          this.loadSubjectData();
          this.subjectKey = await this.dataService.getSubjectKeyFromCode(
            this.subjectCode
          );
          await this.dataService.loadSubjectData(this.subjectKey);
          this.loadDesktop();
          // this.unfinishedExamAttempts = false
          //   await this.dataService.getIncompleteExamAttempts(this.subjectKey);
        } else {
          this.authService.router.navigate(["/subjects"]);
        }
        if (!membershipService.packageValid) {
          this.authService.router.navigate(["/account"]);
        }
      }
    });
  }

  getSubjectCode(url: string): string {
    const parts = url.split("/");
    return parts[2]; // Assuming the URL format is always /desktop/xxx/exam
  }

  getDesktopType(url: string): string {
    const parts = url.split("/");
    return parts[3]; // Assuming the URL format is always /desktop/xxx/exam
  }

  loadSubjectData() {
    this.authService.fs
      .collection("subjects", (ref) =>
        ref.where("code", "==", this.subjectCode)
      )
      .get()
      .subscribe((data) => {
        data.forEach(async (d) => {
          this.subjectData = d.data() as SubjectData;
          this.loadAd();
          this.subjectCodeDisplay = this.subjectData["codeDisplay"];
          this.subjectName = this.subjectData["name"];
          this.acceptingActuaryReviews = this.subjectData["reviews"]["actuary"];
          this.acceptingPeerReviews = this.subjectData["reviews"]["peer"];
          this.acceptingAiReviews = this.subjectData["reviews"]["ai"];
          this.canRequestPeerReview = await this.authService.peerReviews(
            this.authService.userInfo.uid,
            this.subjectCodeDisplay.toUpperCase()
          );
        });
      });
  }

  loadAd() {
    if (this.subjectData.advertiser) {
      if (this.subjectData.advertiser.active) {
        this.showAd = true;
      }
    }
  }

  advertiserPopup() {
    if (this.subjectData.advertiser) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = "50vw";
      dialogConfig.data = this.subjectData.advertiser;
      this.dialog
        .open(AdvertiserPopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          console.log("The dialog was closed");
        });
      this.notificationsService.postToSlack(
        `${this.subjectData.advertiser.subject} -> ${this.subjectData.advertiser.name} -> POPUP OPENED`,
        SlackChannels.advertisers
      );
    }
  }

  advertiserWebsite() {
    if (this.subjectData.advertiser) {
      const url = new URL(this.subjectData.advertiser.website);
      if (url.hostname !== window.location.hostname) {
        window.open(url.href, "_blank");
      } else {
        console.error("Cannot open a local URL in a new tab");
      }
    }
  }

  setCurrentSubjectCode(currentSubjectCode, userId) {
    this.authService.fs
      .collection("users")
      .doc(userId)
      .update({ currentSubjectCode });
  }

  loadDesktop() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    const desktopType = this.getDesktopType(this.authService.router.url);
    this.authService.fs
      .collection("desktop_mcq")
      .doc(`${userId}_${subjectId}`)
      .snapshotChanges()
      .subscribe((snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
          if (this.desktop.analytics) {
            if (
              !this.topicsLevel1 ||
              JSON.stringify(this.desktop.analytics.topicCoverageLevel1) !==
                JSON.stringify(this.topicsLevel1)
            ) {
              this.topicsLevel1 = this.desktop.analytics
                .topicCoverageLevel1 as Topic[];
            }
            this.allTopicsLevel2 = this.desktop.analytics
              .topicCoverageLevel2 as Topic[];
            this.topicsLevel1.forEach((t) => {
              this.allTopicsLevel2Dict[t.key] = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(t.key)
              );
            });
            if (this.state === State.loading) {
              this.state = State.selectTopic;
            }
            if (
              this.desktop.analytics.lastQuestionTimestamp !== null &&
              this.desktop.focusTopicId &&
              this.desktop.focusSubTopicId
            ) {
              const topicText = this.allTopicsLevel2.filter(
                // @ts-ignore
                (t2) => t2.key.includes(this.desktop.focusSubTopicId)
              )[0].topic;
              this.continue.topicId = this.desktop.focusTopicId;
              this.continue.subTopicId = this.desktop.focusSubTopicId;
              this.continue.visible = true;
              this.continue.text = `Continue Topic: ${topicText}`;
            } else {
              this.continue.visible = false;
            }
          }
          if (this.desktop.focusTopicId && this.desktop.focusSubTopicId) {
            this.filterSubtopics();
          }
          if (this.desktop.aiEnabled && this.desktop.questions) {
            this.questionsToDo = [];
            this.desktop.questions.forEach((qd) => {
              const question = this.dataService.allSubjectQuestions.find(
                (q) => q.key === qd.questionId
              );
              if (question) {
                // question.recommendationMeta = qd;
                // this.questionsToDo.push(question);
              }
            });
          }
        } else {
          console.log("No payload");
          this.notificationsService.createTask(Tasks.refreshMCQAnalytics, {
            userId,
            subjectId,
            desktopType,
          });
        }
      });
  }

  continueQuestions() {
    this.setFocusSubTopicId(this.continue.topicId, this.continue.subTopicId);
  }

  filterSubtopics() {
    if (this.desktop.focusTopicId) {
      this.topicsLevel2 = this.allTopicsLevel2.filter(
        // @ts-ignore
        (t) => t.level == 2 && t.key.includes(this.desktop.focusTopicId)
      );
    }
  }

  async loadQuestion(questionData: QuestionData): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.membershipService.packageValid) {
        try {
          this.canAddSheet = true;
          this.currentQuestionLoadedTimestamp = new Date().getTime();
          this.questionData = questionData;
          this.selectedQuestionKey = this.questionData.key;
          this.scroll();
          this.state = State.doQuestions;
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        this.authService.router.navigate(["/account"]);
        this.notificationsService.snack("Subscription expired");
      }
    });
  }

  setFocusSubTopicId(focusTopicId, focusSubTopicId) {
    console.log(focusTopicId);
    console.log(focusSubTopicId);

    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    // @ts-ignore
    this.selectedTopicL1 = this.desktop.analytics.topicCoverageLevel1.find(
      (t) => t.key === focusTopicId
    );
    // @ts-ignore
    this.selectedTopicL2 = this.desktop.analytics.topicCoverageLevel2.find(
      (t) => t.key === focusSubTopicId
    );

    if (
      this.desktop.focusSubTopicId !== focusSubTopicId ||
      // @ts-ignore
      this.desktop.questions.length === 0
    ) {
      this.desktop.focusSubTopicId = focusSubTopicId;
      this.authService.fs
        .collection("desktop_mcq")
        .doc(`${userId}_${subjectId}`)
        .update({
          focusTopicId,
          focusSubTopicId,
          questions: [],
          multipleChoiceQuestions: [],
          flashCards: [],
          status: "Waiting ...",
          mcqStatus: "Waiting ...",
          flashCardStatus: "Waiting ...",
          state: "refreshing",
        })
        .then((d) => {
          this.notificationsService.createTask(Tasks.refreshMCQAnalytics, {
            userId,
            subjectId,
          });
        });
    }
    // do the question
    this.doQuestions();
  }

  async doQuestions() {
    this.selectedQuestionKey = undefined;

    this.loadingDescription = Loading.question;
    this.state = State.loading;

    // Multiple choice questions
    // console.log('MCQ TO DO');
    this.mcqsToDo = [...this.dataService.allSubjectMCQs];

    // filter MCQs for subtopic
    if (this.desktop.focusSubTopicId !== undefined) {
      this.mcqsToDo = this.mcqsToDo.filter(
        // @ts-ignore: Object is possibly 'undefined'.
        (q) => q.selectedTopicId === this.desktop.focusSubTopicId
      );
    }

    // Filter MCQs for those that have not been answered
    if (this.selectedTopicL2 && this.selectedTopicL2["questionsNotAttempted"]) {
      this.mcqsToDo = this.mcqsToDo.filter(
        // @ts-ignore
        (mcq) => this.selectedTopicL2["questionsNotAttempted"].includes(mcq.id)
      );
    }

    this.nextMCQ();
  }

  async nextQuestion() {
    this.loadingDescription = Loading.question;
    this.state = State.loading;
    let index = 0;
    if (this.selectedQuestionKey) {
      index =
        this.questionsToDo.findIndex(
          (q) => q.key === this.selectedQuestionKey
        ) + 1;
      if (
        !index ||
        index >= this.questionsToDo.length ||
        !this.questionsToDo[index]
      )
        index = 0;
    }
    await this.loadQuestion(this.questionsToDo[index]);
  }

  removeAttemptedQuestion(questionId: string) {
    if (this.desktop.questions) {
      this.desktop.questions = this.desktop.questions.filter(
        (question) => question.questionId !== questionId
      );
      const userId = this.authService.userInfo.uid;
      const subjectId = this.subjectKey;
      this.authService.fs
        .collection("desktop")
        .doc(`${userId}_${subjectId}`)
        .update({ questions: this.desktop.questions });
    }
  }

  scroll() {
    try {
      this.subjectsHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }

  backToMenu() {
    this.notificationsService
      .snackConfirm("Go back?", 7500)
      .onAction()
      .subscribe(() => {
        this.min_remaining = 0;
        this.sec_remaining = 0;
        this.state = State.selectTopic;
        this.scroll();
      });
  }

  doMultipleChoice() {
    const userId = this.authService.userInfo.uid;
    const subjectId = this.subjectKey;
    this.authService.router.navigate([`/mcq/${userId}_${subjectId}`]);
  }

  async loadMCQ(questionData: MultipleChoiceQuestion): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.membershipService.packageValid) {
        try {
          this.currentQuestionLoadedTimestamp = new Date().getTime();
          this.currentQuestion = questionData;
          this.mcqData = questionData;
          this.selectedQuestionKey = this.mcqData.key;
          this.state = State.doQuestions;
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        this.authService.router.navigate(["/account"]);
        this.notificationsService.snack("Subscription expired");
      }
    });
  }

  async nextMCQ() {
    this.loadingDescription = Loading.question;
    this.state = State.loading;
    let index = 0;

    // console.log(this.selectedQuestionKey)

    if (this.selectedQuestionKey) {
      index =
        this.mcqsToDo.findIndex((q) => q.key === this.selectedQuestionKey) + 1;
      if (!index || index >= this.mcqsToDo.length || !this.mcqsToDo[index])
        index = 0;
    }
    await this.loadMCQ(this.mcqsToDo[index]);
  }

  async serverMCQ() {
    this.mcqsToDo = [...this.dataService.allSubjectMCQs];
    this.loadMCQ(this.mcqsToDo[0]);
  }

  selectOption(selectedAnswer: MultipleChoiceQuestionAnswer) {
    this.selectedAnswer = selectedAnswer;
  }

  viewAnswer() {
    this.showAnswer = true;
    if (this.currentQuestion && this.selectedAnswer) {
      const attempt: MultipleChoiceQuestionAttempt = {
        multipleChoiceQuestionId: this.currentQuestion.id,
        proficiency: this.selectedAnswer.correctChoice,
        userId: this.desktop.userId,
        questionId: this.currentQuestion.questionId,
        timestamp: new Date().getTime(),
        subjectId: this.subjectKey,
      };
      this.authService.fs
        .collection("multiple_choice_attempts")
        .add(attempt)
        .then((d) => {
          this.notificationsService.createTask(Tasks.refreshMCQAnalytics, {
            userId: this.desktop.userId,
            subjectId: this.subjectKey,
          });
        });
    }
  }

  next() {
    this.showAnswer = false;
    this.selectedAnswer = undefined;
    this.selectedOption = undefined;
    let nextIndex = this.mcqsToDo.indexOf(this.currentQuestion!) + 1;
    if (nextIndex < this.mcqsToDo.length) {
      this.loadMCQ(this.mcqsToDo[nextIndex]);
    }
  }
}
