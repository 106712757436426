import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { MembershipService } from "../../services/membership/membership.service";
import { environment } from "../../../../types/environments/environment";
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  appName = environment.appName;
  constructor(
    public authService: AuthService,
    public membershipService: MembershipService
  ) {}

  ngOnInit() {
    // Any remaining initialization logic
  }
}
