import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import {
  Desktop,
  Flashcard,
  FlashcardProficiency,
  SubjectData,
  Topic,
} from "../../../../types/types";

enum State {
  loading = "loading",
  doFlashCards = "doFlashCards",
}

enum Loading {
  pageLoad = "Loading flashcards, hang tight ...",
}

@Component({
  selector: "app-flashcards",
  templateUrl: "./flashcards.component.html",
  styleUrls: ["./flashcards.component.scss"],
})
export class FlashcardsComponent implements OnInit {
  state: State = State.loading;
  desktopId: string;
  desktop: Desktop;
  flashCardsToDo: Flashcard[];
  loadingDescription: Loading = Loading.pageLoad;
  selectedFlashCard: number = 0;
  showAnswer = false;
  subjectName = "";
  topicName = "";
  subTopicName = "";

  constructor(
    public authService: AuthService,
    public notificationsService: NotificationsService,
    public membershipService: MembershipService
  ) {
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.desktopId = this.getDesktopId(this.authService.router.url);
        this.authService.userInfo = await this.authService.getUserInfoOnce(
          user.uid
        );
        this.load();
      } else {
        this.authService.router.navigate(["/"]);
      }
      if (!membershipService.packageValid) {
        this.authService.router.navigate(["/account"]);
      }
    });
  }

  getDesktopId(url: string): string {
    return url.replace("/flashcards/", "");
  }

  load() {
    this.authService.fs
      .collection("desktop")
      .doc(this.desktopId)
      .snapshotChanges()
      .subscribe(async (snapshot) => {
        if (snapshot.payload.exists) {
          this.desktop = snapshot.payload.data() as Desktop;
          if (this.desktop.focusTopicId && this.desktop.focusSubTopicId) {
            this.subjectName = await this.getSubjectName(
              this.desktop.subjectId
            );
            this.topicName = await this.getTopicName(this.desktop.focusTopicId);
            this.subTopicName = await this.getTopicName(
              this.desktop.focusSubTopicId
            );
            this.flashCardsToDo = await this.getCards(
              this.desktop.subjectId,
              this.desktop.focusSubTopicId
            );
          }
          this.state = State.doFlashCards;
        } else {
          console.log("No payload");
          this.authService.router.navigate(["/"]);
        }
      });
  }

  getCards(subjectId: string, subTopicId: string): Promise<Flashcard[]> {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection(
          "flashcards",
          (ref) => ref.where("subjectId", "==", subjectId)
          //.where("subTopicId", "==", subTopicId)
        )
        .get()
        .subscribe((querySnapshot) => {
          if (!querySnapshot.empty) {
            const cards = querySnapshot.docs.map(
              (doc) => doc.data() as Flashcard
            );
            resolve(cards);
          } else {
            reject(
              new Error(
                "No flashcards found for the given subject and subtopic"
              )
            );
          }
        });
    });
  }

  getSubjectName(subjectId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("subjects")
        .doc(subjectId)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            const subjectData = doc.data() as SubjectData;
            resolve(subjectData.name);
          } else {
            reject(new Error("Subject not found"));
          }
        });
    });
  }

  getTopicName(topicId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authService.fs
        .collection("topics")
        .doc(topicId)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            const topic = doc.data() as Topic;
            resolve(topic.topic);
          } else {
            reject(new Error("Topic not found"));
          }
        });
    });
  }

  setProficiency(proficiency: FlashcardProficiency) {
    this.showAnswer = false;
    this.flashCardsToDo.splice(this.selectedFlashCard, 1);
  }

  ngOnInit() {}
}
