import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NouisliderModule } from "ng2-nouislider";
import { LandingComponent } from "./landing/landing.component";
import { SignupComponent } from "./signup/signup.component";
import { DesktopComponent } from "./desktop/desktop.component";
import { DesktopMCQComponent } from "./desktop-mcq/desktop-mcq.component";
import { DialogDifficultyComponent } from "../dialogs/dialog-difficulty/dialog-difficulty.component";
import { CreditInstructionsComponent } from "../dialogs/credit-instructions/credit-instructions.component";
import { ExamBreakdownComponent } from "./exam-breakdown/exam-breakdown.component";
import { MarkComponent } from "./admin/mark/mark.component";
import { MarkConfirmComponent } from "../dialogs/mark-confirm/mark-confirm.component";
import { ReviewTypeSelectedTopicComponent } from "../dialogs/review-type-selected-topic/review-type-selected-topic.component";
import { ExamPressureEndComponent } from "../dialogs/exam-pressure-end/exam-pressure-end.component";
import { ExamPressureSubmitComponent } from "../dialogs/exam-pressure-submit/exam-pressure-submit.component";
import { AttemptsComponent } from "./admin/attempts/attempts.component";
import { AuthService } from "../services/auth/auth.service";
import { AuthGuardService } from "../services/guards/auth-guard.service";
import { MatDialogModule } from "@angular/material";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { ChartsModule } from "ng2-charts";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MaterialModule } from "../material/material.module";
import { MarkersComponent } from "./admin/markers/markers.component";
import { PackagesComponent } from "../components/packages/packages.component";
import { PerformanceComponent } from "../components/performance/performance.component";
import { RenderQuestionComponent } from "../components/render-question/render-question.component";
import { SubQuestionCollectionComponent } from "../components/sub-question-selection/sub-question-selection.component";
import { MarkerSummaryComponent } from "../components/marker-summary/marker-summary.component";
import { AssignmentSummaryComponent } from "../components/assignment-summary/assignment-summary.component";
import { TermsAndConditionsPopComponent } from "../dialogs/terms-and-conditions-pop/terms-and-conditions-pop.component";
import { JobBoardInterestPopComponent } from "../dialogs/job-board-interest-pop/job-board-interest-pop.component";
import { UserComponent } from "./admin/user/user.component";
import { PaperManagementComponent } from "./admin/paper-management/paper-management.component";
import { AccountComponent } from "./account/account.component";
import { ExamEnvComponent } from "./exam-env/exam-env.component";
import { ExamSelectionComponent } from "../dialogs/exam-selection/exam-selection.component";
import { ReviewTypeExamComponent } from "../dialogs/review-type-exam/review-type-exam.component";
import { AddQuestionComponent } from "../pages/admin/add-question/add-question.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MarkerComponent } from "./marker/marker.component";
import { AI } from "../dialogs/ai/ai.component";
import { AdvertiserPopupComponent } from "../dialogs/advertiser/advertiser.component";
import { QuestionsComponent } from "../dialogs/questions/questions.component";
import { QuestionManagementComponent } from "./admin/question-management/question-management.component";
import { SubjectsComponent } from "./subjects/subjects.component";
import { AdminSubjectsComponent } from "./admin/subjects/subjects.component";
import { MatInputModule } from "@angular/material";
import { FlashcardsComponent } from "./flashcards/flashcards.component";
import { McqComponent } from "./mcq/mcq.component";
import { ProfessionalBodySelectorComponent } from "../components/professional-body-selector/professional-body-selector.component";
import { McqAdminComponent } from "./admin/mcq/mcq.component";
import { PodcastComponent } from "../dialogs/podcast/podcast.component";
import { TopicListComponent } from "../components/topic-list/topic-list.component";
import { EmailConfirmationComponent } from "../components/email-confirmation/email-confirmation.component";
import { MarkingCreditsComponent } from "../components/marking-credits/marking-credits.component";
import { InvoicesComponent } from "../components/invoices/invoices.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    ChartsModule,
    NouisliderModule,
    CKEditorModule,
    MaterialModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  declarations: [
    LandingComponent,
    SignupComponent,
    SubjectsComponent,
    DesktopComponent,
    DialogDifficultyComponent,
    CreditInstructionsComponent,
    TermsAndConditionsPopComponent,
    JobBoardInterestPopComponent,
    ExamBreakdownComponent,
    MarkComponent,
    MarkConfirmComponent,
    ReviewTypeSelectedTopicComponent,
    ExamPressureEndComponent,
    ExamPressureSubmitComponent,
    AttemptsComponent,
    MarkersComponent,
    PackagesComponent,
    PerformanceComponent,
    MarkerSummaryComponent,
    AssignmentSummaryComponent,
    RenderQuestionComponent,
    SubQuestionCollectionComponent,
    UserComponent,
    PaperManagementComponent,
    AccountComponent,
    ExamEnvComponent,
    ExamSelectionComponent,
    ReviewTypeExamComponent,
    AddQuestionComponent,
    MarkerComponent,
    AI,
    QuestionsComponent,
    QuestionManagementComponent,
    AdvertiserPopupComponent,
    AdminSubjectsComponent,
    FlashcardsComponent,
    McqComponent,
    McqAdminComponent,
    PodcastComponent,
    ProfessionalBodySelectorComponent,
    TopicListComponent,
    DesktopMCQComponent,
    EmailConfirmationComponent,
    MarkingCreditsComponent,
    InvoicesComponent,
  ],
  providers: [AuthService, AuthGuardService],
  entryComponents: [
    DialogDifficultyComponent,
    CreditInstructionsComponent,
    TermsAndConditionsPopComponent,
    JobBoardInterestPopComponent,
    MarkConfirmComponent,
    ReviewTypeSelectedTopicComponent,
    ExamPressureEndComponent,
    ExamPressureSubmitComponent,
    ExamSelectionComponent,
    ReviewTypeExamComponent,
    AI,
    QuestionsComponent,
    AdvertiserPopupComponent,
    PodcastComponent,
    ProfessionalBodySelectorComponent,
    EmailConfirmationComponent,
  ],
  exports: [RenderQuestionComponent],
})
export class PagesModule {}
