import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { NotificationsService } from "../../services/notifications/notifications.service";
import {
  SetCreditsTask,
  Tasks,
  SetPackageTask,
  Invoice,
  User,
  Price,
  Subscription,
  SubscriptionName,
  Prices,
  ProfessionalBodies,
  stage,
  Currency,
} from "../../../../types/types";
import { MatDialog } from "@angular/material";
import { environment } from "../../../../types/environments/environment";
import { Observable } from "rxjs";
enum State {
  activatingTrial = "activatingTrial",
  trial = "trial",
  awaitingPayment = "awaitingPayment",
  active = "active",
  expired = "expired",
  marker = "marker",
}

@Injectable({
  providedIn: "root",
})
export class MembershipService {
  public packageValid = true;
  public subDaysRemain = 0;
  public subscription: Subscription;
  public prospectiveSubscription: Subscription | undefined;
  public hasOutstandingPop: boolean = false;
  private invoices: Invoice[] = [];
  private onTrial: boolean = false;
  private status: string = "inactive";
  private subscriptionState: State;
  private subscriptionCost: Price;
  private showBuyButton: boolean = true;
  constructor(
    private auth: AuthService,
    private notifications: NotificationsService,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.auth.user.subscribe(async (user) => {
      if (user) {
        const userInfo = await this.auth.getUserInfoOnce(user.uid);
        if (userInfo) {
          if (userInfo.professionalBody) {
            this.load(userInfo.uid);
          }
        }
      }
    });
  }

  load(uid: string) {
    this.loadSubscription(uid);
    this.getSubscriptionCost(uid);
    this.loadInvoices(uid);
  }

  async loadSubscription(uid: string) {
    await this.auth.loadUserRights();
    if (this.auth.marker) {
      await this.handleMarker();
    } else {
      this.getSubscription(uid).subscribe(async (data) => {
        await this.handleSubscriptionData(data, uid);
      });
    }
  }

  private async handleSubscriptionData(data: Subscription[], uid: string) {
    if (data.length === 0) {
      this.activateTrial(uid);
    } else {
      await this.handleSubscription(data);
    }
  }

  private async handleMarker() {
    this.packageValid = true;
    this.subDaysRemain = 999;
    this.subscriptionState = State.marker;
    this.subscription = {
      uid: this.auth.userInfo.uid,
      membership_descr: SubscriptionName.marker,
      timestamp: new Date().getTime(),
      day_limit: 999,
      verified: true,
      user_email: this.auth.userInfo.email,
      cost: 0,
      professionalBody: this.auth.professionalBody!,
      currency: Currency.ZAR,
      platform: environment.platform,
      discount: {
        price: 0,
        description: SubscriptionName.marker,
        quantity: 0,
      },
    };
  }

  private async handleSubscription(subscriptions: Subscription[]) {
    try {
      // Get verified subscription
      const verifiedSubscriptions = subscriptions.filter((s) => s.verified);
      this.subscription = verifiedSubscriptions[0];

      // Get prospective subscription
      this.prospectiveSubscription = undefined;
      if (subscriptions[0]) {
        if (subscriptions[0].verified === false) {
          this.prospectiveSubscription = subscriptions[0];
          console.log("Prospective subscription found");
        }
      }

      if (this.subscription) {
        if (!this.subscription.timestamp || !this.subscription.day_limit) {
          // Calculate remaining days
          throw new Error(
            "Invalid subscription data: missing timestamp or day limit"
          );
        }
        this.subDaysRemain = this.calculateDaysLeft(this.subscription);

        // Check if trial subscription
        if (!this.subscription.membership_descr) {
          throw new Error(
            "Invalid subscription data: missing membership description"
          );
        }
        this.onTrial =
          this.subscription.membership_descr === SubscriptionName.trial;

        // Handle subscription status
        if (this.onTrial) {
          this.handleTrialStatus();
        } else {
          this.handleNonTrialStatus();
        }
      }
      this.showHideBuyButton();
    } catch (error) {
      console.error("Error handling subscription:", error);
      this.packageValid = false;
      this.status = "Error loading subscription details";
      this.subscriptionState = State.expired;
    }
  }

  showHideBuyButton() {
    if (this.prospectiveSubscription) {
      this.showBuyButton = false;
      return;
    }
    if (this.onTrial || this.subDaysRemain <= 0) {
      this.showBuyButton = true;
      return;
    }
    this.showBuyButton = false;
  }

  private handleTrialStatus() {
    if (this.subDaysRemain === 1) {
      this.status = "One day remaining on trial.";
      this.notifications.snack(
        "One day remaining on trial. Purchase a subscription to get unlimited access."
      );
    } else if (this.subDaysRemain > 1) {
      this.status = `${this.subDaysRemain} days remaining on trial.`;
      this.notifications.snack(
        `${this.subDaysRemain} days remaining on trial. Purchase a subscription to get unlimited access.`
      );
    } else {
      this.handleExpiredSubscription();
    }
    this.subscriptionState = State.trial;
  }

  private handleNonTrialStatus() {
    if (this.subDaysRemain <= 0) {
      this.handleExpiredSubscription();
    } else {
      this.status = `Your ${this.subscription.membership_descr} subscription ends on ${this.semesterEndingDate}.`;
      this.subscriptionState = State.active;
    }
  }

  private handleExpiredSubscription() {
    this.packageValid = false;
    this.status = "Your subscription has expired.";
    this.subscriptionState = State.expired;
    this.router.navigate(["/account"]);
  }

  calculateDaysLeft(subscription: Subscription): number {
    const startTimestamp = subscription.timestamp;
    const dayLimit = subscription.day_limit;
    const used =
      (new Date().getTime() - startTimestamp) / (1000 * 60 * 60 * 24);
    let days = dayLimit - used;
    days = Math.ceil(days);
    if (!isNaN(days)) {
      return Math.max(0, days);
    } else {
      return 0;
    }
  }

  getSubscription(uid: string): Observable<Subscription[]> {
    return this.auth.fs
      .collection("memberships", (ref) =>
        ref.where("uid", "==", uid).orderBy("timestamp", "desc")
      )
      .valueChanges() as Observable<Subscription[]>;
  }

  async activateTrial(uid: string) {
    this.subscriptionState = State.activatingTrial;
    this.status = "Activating trial...";
    this.purchaseSubscription(uid, true);
  }

  async purchaseSubscription(
    uid: string,
    trial: boolean = false,
    credits: number = 0
  ) {
    if (this.auth.professionalBody) {
      const data: SetPackageTask = {
        uid,
        type: Tasks.setPackage,
        platform: environment.platform,
        credits,
        professionalBody: this.auth.professionalBody,
        subscription: trial ? SubscriptionName.trial : SubscriptionName.premium,
      };
      this.notifications.createTask(Tasks.setPackage, data);
    }
    if (!trial) {
      this.notifications.snack(
        "Check your email for your invoice. Your subscription will be activated once proof of payment is uploaded 🚀"
      );
    }
  }

  async getLatestSubscription(
    uid: string,
    active: boolean
  ): Promise<Subscription | null> {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("memberships", (ref) =>
          ref
            .where("uid", "==", uid)
            .where("verified", "==", active)
            .orderBy("timestamp", "asc")
            .limitToLast(1)
        )
        .get()
        .subscribe((subscriptions) => {
          subscriptions.forEach((subscription) => {
            const subscriptionData = subscription.data() as Subscription;
            subscriptionData["id"] = subscription.id;
            subscriptionData["daysLeft"] =
              this.calculateDaysLeft(subscriptionData);
            resolve(subscriptionData as Subscription);
          });
          if (subscriptions.empty) {
            resolve(null);
          }
        });
    });
  }

  async getCredits(uid: string) {
    return new Promise((resolve, reject) => {
      this.auth.fs
        .collection("users")
        .doc(uid)
        .get()
        .subscribe((d) => {
          if (d.exists) {
            if (d.data()) {
              const user = d.data() as User;
              resolve(user.credits);
            } else {
              reject();
            }
          } else {
            reject();
          }
        });
    });
  }

  async changeCredits(
    credits: number,
    description: string,
    sendEmail: boolean = false,
    uid: string = "NULL"
  ) {
    if (uid === "NULL") {
      uid = this.auth.userInfo.uid;
    }
    const data: SetCreditsTask = {
      uid,
      credits,
      description,
      sendEmail,
      type: Tasks.credits,
      platform: environment.platform,
    };
    this.notifications.createTask(Tasks.credits, data);
  }

  get semesterEndingDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    if (month <= 6) {
      return `30 June ${year}`;
    } else {
      return `31 December ${year}`;
    }
  }

  async getSubscriptionCost(uid: string) {
    const professionalBody = (await this.auth.getUserInfoOnce(uid))
      .professionalBody;
    if (professionalBody) {
      const d = await this.auth.fs
        .collection("config")
        .doc("subscription")
        .get()
        .toPromise();
      const subscriptionCost = d.data() as Prices;
      this.subscriptionCost = subscriptionCost[professionalBody];
    }
  }

  async loadInvoices(uid: string) {
    const sessionTimestamp = this.auth.getSessionTS();
    this.auth.fs
      .collection("invoices", (ref) =>
        ref
          .where("uid", "==", uid)
          .orderBy("timestamp", "desc")
      )
      .snapshotChanges()
      .subscribe((d) => {
        this.invoices = [];
        this.hasOutstandingPop = false;
        d.forEach((invoice) => {
          const invoiceData = invoice.payload.doc.data() as Invoice;
          invoiceData["id"] = invoice.payload.doc.id;
          if (!invoiceData.POP) {
            this.hasOutstandingPop = true;
          }
          this.invoices.push(invoiceData);
        });
      });
  }

  async deleteSubscription(docId: string) {
    this.auth.fs.collection("memberships").doc(docId).delete();
  }

  async getSubDaysRemain(uid: string) {
    const sub = await this.getLatestSubscription(uid, true);
    if (sub) {
      return this.calculateDaysLeft(sub);
    }
    return 1;
  }
}
