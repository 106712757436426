import { Component, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AuthService } from "../../services/auth/auth.service";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MembershipService } from "../../services/membership/membership.service";
import { AnalyticsService } from "../../services/analytics/analytics.service";
import { DataServiceService } from "../../services/data/data-service.service";
import { environment } from "../../../../types/environments/environment";
import { ProfessionalBodySelectorComponent } from "../../components/professional-body-selector/professional-body-selector.component";
import { SubjectData } from "../../../../types/types";

@Component({
  selector: "app-subjects",
  templateUrl: "./subjects.component.html",
  styleUrls: ["./subjects.component.scss"],
})
export class SubjectsComponent {
  @ViewChild("subjects") subjectsHTML: ElementRef;
  public appName = environment.appName;
  public appDescription = environment.description;
  public completedQuestionLatestStatus = {};
  public subjectProgress = {};
  public subjectsList = [];
  public lastUsedSubject: any;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public membershipService: MembershipService,
    public notificationsService: NotificationsService,
    public analyticsService: AnalyticsService,
    public dataService: DataServiceService
  ) {
    if (window.innerWidth < 1000) {
      this.authService.goToPage("/landing");
      this.notificationsService.snack(
        "Please use a bigger browser for the best experience"
      );
    }
    this.authService.user.subscribe(async (user) => {
      if (user) {
        this.dataService.loadSubjects(user.uid);
        const subDaysRemain = await this.membershipService.getSubDaysRemain(
          user.uid
        );
        if (subDaysRemain <= 0) {
          this.authService.goToPage("/account");
        }
      }
    });
  }

  scroll() {
    try {
      this.subjectsHTML.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } catch (e) {
      console.log(e);
    }
  }

  notifyMe(subjectCode) {
    this.authService.fs
      .collection("subject_notifications_requests")
      .add({
        subjectCode: subjectCode,
        email: this.authService.userDetails.email,
        uid: this.authService.userDetails.uid,
        time: new Date().getTime(),
      })
      .then((d) => {
        this.notificationsService.snack(
          "Notification request for " + subjectCode
        );
      });
  }

  goToAccount() {
    this.authService.goToPage("/account");
  }
}
