import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { SignupComponent } from "./pages/signup/signup.component";
import { LandingComponent } from "./pages/landing/landing.component";
import { SubjectsComponent } from "./pages/subjects/subjects.component";
import { MarkerComponent } from "./pages/marker/marker.component";
import { DesktopComponent } from "./pages/desktop/desktop.component";
import { DesktopMCQComponent } from "./pages/desktop-mcq/desktop-mcq.component";
import { AccountComponent } from "./pages/account/account.component";
import { ExamBreakdownComponent } from "./pages/exam-breakdown/exam-breakdown.component";
import { MarkComponent } from "./pages/admin/mark/mark.component";
import { MarkersComponent } from "./pages/admin/markers/markers.component";
import { HomeComponent } from "./pages/admin/home/home.component";
import { SubjectComponent } from "./pages/admin/subject/subject.component";
import { UsersComponent } from "./pages/admin/users/users.component";
import { UserComponent } from "./pages/admin/user/user.component";
import { AttemptsComponent } from "./pages/admin/attempts/attempts.component";
import { AuthGuardService } from "./services/guards/auth-guard.service";
import { AdminGuardService } from "./services/guards/admin-guard.service";
import { MarkerGuardService } from "./services/guards/marker-guard.service";
import { ExamEnvComponent } from "./pages/exam-env/exam-env.component";
import { PaperManagementComponent } from "./pages/admin/paper-management/paper-management.component";
import { QuestionManagementComponent } from "./pages/admin/question-management/question-management.component";
import { AdminSubjectsComponent } from "./pages/admin/subjects/subjects.component";
import { FlashcardsComponent } from "./pages/flashcards/flashcards.component";
import { McqComponent } from "./pages/mcq/mcq.component";
import { McqAdminComponent } from "./pages/admin/mcq/mcq.component";

const routes: Routes = [
  { path: "", redirectTo: "landing", pathMatch: "full" },
  {
    path: "landing",
    component: LandingComponent,
  },
  {
    path: "login",
    component: SignupComponent,
  },
  {
    path: "subjects",
    component: SubjectsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "marker",
    component: MarkerComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "papers",
    component: PaperManagementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "papers/:id",
    component: QuestionManagementComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "desktop/:id/exam",
    component: DesktopComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "desktop/:id/mcq",
    component: DesktopMCQComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "flashcards/:id",
    component: FlashcardsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "exam/:id",
    component: ExamEnvComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "exam-breakdown/:id",
    component: ExamBreakdownComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "admin",
    component: HomeComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "account",
    component: AccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "mark/:id",
    component: MarkComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "attempts",
    component: AttemptsComponent,
    canActivate: [MarkerGuardService],
  },
  {
    path: "admin/markers",
    component: MarkersComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/mcq-admin",
    component: McqAdminComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/users",
    component: UsersComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/users/:uid",
    component: UserComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/subjects",
    component: AdminSubjectsComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/subjects/:subjectId",
    component: SubjectComponent,
    canActivate: [AdminGuardService],
  },
  {
    path: "admin/subjects/:subjectId/mcq",
    component: McqAdminComponent,
    canActivate: [AuthGuardService],
  },
  { path: "**", redirectTo: "landing" },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
